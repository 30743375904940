<template>
  <div id="central-logo" class="central-logo">


    <img id="logo-graphic" alt="CJ Logo" src="~@/assets/CJ Logo_01.svg" class="logo-symbol" >
<!--    <svg id="logo-graphic" alt="CJ Logo" class="logo-symbol" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 750 750">-->
<!--      <filter id="dropshadow" height="130%">-->
<!--        <feGaussianBlur in="SourceAlpha" stdDeviation="3"/> &lt;!&ndash; stdDeviation is how much to blur &ndash;&gt;-->
<!--        <feOffset dx="2" dy="2" result="offsetblur"/> &lt;!&ndash; how much to offset &ndash;&gt;-->
<!--        <feComponentTransfer>-->
<!--          <feFuncA type="linear" slope="0.5"/> &lt;!&ndash; slope is the opacity of the shadow &ndash;&gt;-->
<!--        </feComponentTransfer>-->
<!--        <feMerge>-->
<!--          <feMergeNode/> &lt;!&ndash; this contains the offset blurred image &ndash;&gt;-->
<!--          <feMergeNode in="SourceGraphic"/> &lt;!&ndash; this contains the element that the filter is applied to &ndash;&gt;-->
<!--        </feMerge>-->
<!--      </filter>-->
<!--      <filter id="black-glow">-->
<!--        <feColorMatrix type="matrix" values=-->
<!--            "1 0 0 0   0-->
<!--             0 1 0 0   0-->
<!--             0 0 1 0   0-->
<!--             0 0 0 1   0"/>-->
<!--        <feGaussianBlur stdDeviation="10" result="coloredBlur"/>-->
<!--        <feMerge>-->
<!--          <feMergeNode in="coloredBlur"/>-->
<!--          <feMergeNode in="SourceGraphic"/>-->
<!--        </feMerge>-->
<!--      </filter>-->

<!--      <path class="st0" d="M680.3 157.2c-5.7-7.6-16.1-8.9-22.8-2.2 -17.7 17.7-51.5 51.5-70.3 70.3 -7.4 7.4-9 19-3.7 28.1 16.1 28.3 32.9 68.2 32.9 121.7 0 0 0 0 0 0 0 133.3-105.6 231-218.6 240.3 -4.5 0.4-8.5-3.1-8.5-7.7V458.8c0-5.3-4.3-9.6-9.6-9.6h-1.6c-7.7 0-15.1 3.1-20.5 8.5L246.5 568.6c-5.4 5.4-8.4 12.6-8.4 20.2l0 125.8c0 5.9 4 11.2 9.4 13.2 23.1 8.3 67.9 22.2 127.4 22.2C582.2 750 750 582.2 750 375 750 280 715.1 205.8 680.3 157.2z"/>-->
<!--      <path class="st1" d="M564.9 382.2c-2.7 72.6-46.1 134.8-108.1 164.4 -0.2 0.1-0.4-0.2-0.2-0.3 46.7-27.6 79.8-76.6 84.9-134.8 8.2-95.6-62.6-179.8-158.3-188 -95.6-8.2-179.8 62.6-188 158.3 -2.7 31.7 3.2 62.1 15.9 88.9 0.1 0.2-0.2 0.4-0.3 0.2 -16.4-28.1-25.8-60.8-25.8-95.7 0-100.4 77.9-182.6 176.5-189.6C473.8 177.5 569.1 269.6 564.9 382.2z"/>-->
<!--      <path class="st2" d="M592.6 95.1l-70.7 70.8c-5.8 5.8-14.8 6.9-21.8 2.6 -37-22.4-80.5-35.2-127-34.8 -131.2 0-239.3 108.1-239.3 239.4 -0.4 46.5 12.4 90 34.8 126.9 4.3 7 3.2 16-2.6 21.9l-70.7 70.7c-7.9 7.9-20.9 6.7-27.3-2.4C25 529.1-0.1 455.1 0 375 0 168.2 168 0 375 0c80.1 0 154.3 25.1 215.2 67.8C599.2 74.2 600.4 87.2 592.6 95.1z"/>-->
<!--      <linearGradient gradientUnits="userSpaceOnUse" x1="374.8" y1="230.2" x2="375.6" y2="738.8">-->
<!--        <stop offset="0.2053" style="stop-color:#000;stop-opacity:0"/>-->
<!--        <stop offset="1" style="stop-color:#000;stop-opacity:0.7"/>-->
<!--      </linearGradient>-->
<!--      <path style="opacity:0.39;fill:url(#Gradient_1_)" class="st3" d="M680.3 157.2c-5.7-7.6-16.1-8.9-22.8-2.2 -17.7 17.7-51.5 51.5-70.3 70.3 -7.4 7.4-9 19-3.7 28.1 16.1 28.3 32.9 68.2 32.9 121.7 0 0 0 0 0 0 0 133.3-105.6 231-218.6 240.3 -4.5 0.4-8.5-3.1-8.5-7.7V458.8c0-5.3-4.3-9.6-9.6-9.6h-1.6c-7.7 0-15.1 3.1-20.5 8.5L246.5 568.6c-5.4 5.4-8.4 12.6-8.4 20.2l0 125.8c0 5.9 4 11.2 9.4 13.2 23.1 8.3 67.9 22.2 127.4 22.2C582.2 750 750 582.2 750 375 750 280 715.1 205.8 680.3 157.2zM564.9 382.2c-2.7 72.6-46.1 134.8-108.1 164.4 -0.2 0.1-0.4-0.2-0.2-0.3 46.7-27.6 79.8-76.6 84.9-134.8 8.2-95.6-62.6-179.8-158.3-188 -95.6-8.2-179.8 62.6-188 158.3 -2.7 31.7 3.2 62.1 15.9 88.9 0.1 0.2-0.2 0.4-0.3 0.2 -16.4-28.1-25.8-60.8-25.8-95.7 0-100.4 77.9-182.6 176.5-189.6C473.8 177.5 569.1 269.6 564.9 382.2zM592.6 95.1l-70.7 70.8c-5.8 5.8-14.8 6.9-21.8 2.6 -37-22.4-80.5-35.2-127-34.8 -131.2 0-239.3 108.1-239.3 239.4 -0.4 46.5 12.4 90 34.8 126.9 4.3 7 3.2 16-2.6 21.9l-70.7 70.7c-7.9 7.9-20.9 6.7-27.3-2.4C25 529.1-0.1 455.1 0 375 0 168.2 168 0 375 0c80.1 0 154.3 25.1 215.2 67.8C599.2 74.2 600.4 87.2 592.6 95.1z"/>-->
<!--    </svg>-->
    <img id="logo-text" alt="CJ Title" src="~@/assets/CJ Logo_Just Name-01.svg" class="logo-text">

  </div>
</template>

<script>
export default {
name: "CentralLogo"
}
</script>

<style scoped lang="scss">
.st0{
  fill:#C85A1E;
  filter: url(#dropshadow);;
}
.st1{
  fill:#646464;
}
.st2{
  fill:#005AAA;
}
.st3{
  opacity:0.39;fill:url(#Gradient_1_);
}

@media (min-width: 640px) {
  #logo-text {
    display: block;
    height: 50pt;
    position:relative;
    left:-35pt;
  }
  .central-logo {
    flex-direction: row;
  }
}
@media (max-width: 640px) {
  #logo-text {
    display: block;
    height: 37.5pt;
    position:relative;
    top:-20pt;
  }
  .central-logo {
    flex-direction: column;
    // margin-bottom: 2em;
  }
}

.central-logo {
  display: flex;
  justify-content: center;
  align-items: center;

  #logo-graphic {
    display: inline-block;
    width: 160pt;
    // padding: 25pt;
    //filter: drop-shadow(0 0 4em black,0 0 4em black,0 0 4em black);
    box-shadow: red;
  }

  #logo-text {
    display: inline-block;
    height: 80pt;
  }
}

</style>
