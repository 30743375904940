<template>
  <div class="middle-bar">
    <div class="segment left-segment"></div>
    <div class="segment middle-left-segment"></div>
    <div class="segment middle-right-segment"></div>
    <div class="segment right-segment"></div>
</div>

</template>

<script>
export default {
  name: "MiddleBar"
}
</script>

<style scoped lang="scss">

.middle-bar-wrapper {
 position: relative;
}

.middle-bar {
  height: 20px;
  width: 100%;
  display:flex;
  flex-direction: row;


  &>.segment {
    display: inline-block;
    position: relative;
    padding: 20px 20px 0 0;
    overflow: hidden;

    //color: #fff;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 20px;
      z-index: -1;

    }

    &.left-segment, &.right-segment {
      flex-basis: 5em;
      flex-grow: 0;
      &:after {
        background: linear-gradient(to bottom, $cj_orange 0%, darken($cj_orange, 10) 100%)
      }
    }

    &.middle-left-segment, &.middle-right-segment {
      flex-basis: 5em;
      flex-grow: 1;
      &:after {
        background: linear-gradient(to bottom, $cj_blue 0%, darken($cj_blue, 10) 100%)
      }
    }

    &.left-segment {
      &:after {
        //background: linear-gradient(to bottom, $cj_orange 0%, darken($cj_orange, 10) 100%);
        //-webkit-transform-origin: 100% 0;
        //-ms-transform-origin: 100% 0;
        transform-origin: 100% 0;
        //-webkit-transform: skew(-45deg);
        //-ms-transform: skew(-45deg);
        transform: skew(-45deg);
      }
    }

    &.middle-left-segment {
      &:after {
        background: $cj_blue;
        //-webkit-transform-origin: 100% 0;
        //-ms-transform-origin: 100% 0;
        transform-origin: bottom left;
        ////-webkit-transform: skew(-45deg);
        ////-ms-transform: skew(-45deg);
        transform: skew(-45deg);

      }
    }
    &.middle-right-segment {
      &:after {
        background: $cj_blue;
        //-webkit-transform-origin: 100% 0;
        //-ms-transform-origin: 100% 0;
        transform-origin: bottom right;
        ////-webkit-transform: skew(-45deg);
        ////-ms-transform: skew(-45deg);
        transform: skew(45deg);

      }
    }
    &.right-segment {
      &:after {
        //background: $cj_orange;
        //-webkit-transform-origin: 100% 0;
        //-ms-transform-origin: 100% 0;
        transform-origin: 100% 0;
        //-webkit-transform: skew(-45deg);
        //-ms-transform: skew(-45deg);
        transform: skew(45deg);
      }
    }

  }
}

</style>
