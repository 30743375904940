<template>
<div class="central-blurb">
  <h1>That’s Awkward...</h1>
  <p>
    Looks like we’re still getting ready.<br/>
    Meanwhile, I have a PDF portfolio ready for you.
  </p>
  <a class="download-button" href="/CJ Digital Portfolio.pdf" target="_blank">Download My Portfolio</a>

</div>
</template>

<script>
export default {
  name: "CentralBlurb",
}
</script>

<style scoped lang="scss">
.central-blurb {

}

h1 {
  font-family: "ProximaNova", Helvetica, Arial, sans-serif;
  color: $cj-gray;
  font-size: 50pt;
}

p {
  font-family: "ProximaNova", Helvetica, Arial, sans-serif;
  color: $cj_blue;
  font-size: 16pt;
  margin-right: 1em;
  margin-left: 1em;
}

@media (max-width: 640px) {
  h1 {
    font-size: 30pt;
  }
  p,.download-button {
    font-size: 16pt;
  }
}

.download-button {
  text-decoration: none;
  display: inline-block;
  font-family: "ProximaNova", Helvetica, Arial, sans-serif;
  background-color: $cj-orange;
  padding: 1em;

  color: white;
  border-radius:0.75em;
  border: 2px $cj_orange solid;
  font-size: 16pt;

  &:hover {
    background-color: lighten($cj-orange, 20%);
    border: 2px lighten($cj-orange, 20%) solid;
  }

  &:active {
    background-color: white;
    color: $cj-orange;
    border: 2px $cj_orange solid;
  }
}
</style>