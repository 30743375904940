<template>
<div class="top-half" >
  <div class="central-logo-holder">
    <central-logo />
  </div>
  <div class="blurb-and-turtle">
    <central-blurb />
    <div class="awkward-turtle">
      <img alt="Awkward Turtle" src="~@/assets/Awkward Turtle_shutterstock_1193761072 [Converted].svg">
    </div>
  </div>
  <middle-bar/>

</div>
</template>

<script>
import CentralLogo from "@/components/CentralLogo";
import CentralBlurb from "@/components/CentralBlurb";
import MiddleBar from "@/components/MiddleBar";

export default {
  name: "TopHalf",
  components: {
    MiddleBar,
    CentralBlurb,
    CentralLogo,

  }
}
</script>

<style scoped lang="scss">

.top-half {
  position:relative;
  min-height:70vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &:before, &:after {
    content: "";
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
  }

  &:after {
    z-index:-5;
    background: linear-gradient(to bottom, rgba(255,255,255,0.9) 0%,rgba(255,255,255,1) 100%); /* W3C */
  }

  &:before {
    z-index:-10;
    background-image: url('~@/assets/Angled Pattern.svg');
    background-size: $angled_background_size;
    background-repeat: repeat;
  }

}


.central-logo-holder {
  justify-content: space-around;
  align-content: space-around;
  flex-grow:1;
  display: flex;
  flex-direction: column;
}
.blurb-and-turtle {
  display: flex;
  flex-direction: row ;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;

    .central-blurb {
      margin: 30pt;
      margin-top: 0;
      min-width: 300pt;
    }

  .awkward-turtle {
    width:200pt;
    height:100pt;
    position:relative;

    img{
      position: absolute;
      width:200pt;
      left:0;
      bottom:-10pt;
    }
  }
}




</style>
