<template>
  <div id="app">
      <top-half></top-half>
      <div class="bottom-half">
      </div>
  </div>
</template>

<script>
import TopHalf from "@/components/TopHalf";

export default {
  name: 'App',
  components: {
    TopHalf,
  }
}
</script>

<style lang="scss">

#app {
  //position: relative;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}
.top-half {
  //flex-grow: 1;
}

.bottom-half {
  position:relative;
  flex-grow: 2;
  min-height: 3em;

  //
  &:after {
    content: "";
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    z-index:-1;
    background: linear-gradient(to bottom, rgba(255,255,255,0.85) 0%,rgba(255,255,255,0.85) 100%); /* W3C */
  }

  &:before {
    content: "";
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    z-index:-2;
    background-image: url('~@/assets/Angled Pattern.svg');
    background-size: $angled_background_size;
    background-repeat: repeat;
  }

}
</style>
